<template>
  <v-container class="container-max-width">
    <v-row class="page-wrapper">
      <v-col offset="2" cols="8">
        <v-row>
          <v-col cols="12" class="align-center">
            <div class="text-center">
              <svg width="130px" height="30px" viewBox="0 0 130 30">
                <circle cx="10" cy="10" r="9" class="filled"/>
                <line x1="19" y1="10" x2="31" y2="10" class="line"/>
                <circle cx="40" cy="10" r="9" class="filled"/>
                <line x1="49" y1="10" x2="61" y2="10" class="line"/>
                <circle cx="70" cy="10" r="9" class="filled"/>
                <line x1="79" y1="10" x2="91" y2="10" class="line"/>
                <circle cx="100" cy="10" r="9" class="not-filled"/>
              </svg>
            </div>
          </v-col>
        </v-row>
        <v-row class="heading-text-wrapper">
          <v-col cols="1" class="mt-3">
            <v-icon x-large @click="$router.go(-1)">
              keyboard_arrow_left
            </v-icon>
          </v-col>
          <v-col class="primary--text text-center" cols="11">
            <p class="text-h1 ml-5">Verify your Store URL</p>
          </v-col>
        </v-row>
        <v-row class="card-wrapper">
          <v-col offset="1">
           <v-card class="pa-15" :loading="loading">
              <v-row><v-col cols="8">
                <v-text-field
                    clearable
                    @click:clear="reset"
                    outlined
                    autofocus
                    label="Store URL"
                    class="rounded-0"
                    height="56px"
                    v-model="storeUrl"
                    @change="$v.storeUrl.$touch()"
                    :error-messages="checkURLErrors"
                >
                  <template slot="append">
                    <v-icon color="green"  v-if="isValidUrl">
                      check_circle
                    </v-icon>
                    <v-icon color="red"  v-if="inValidUrl">
                      error
                    </v-icon>
                  </template>
                </v-text-field>
                <p class="text-caption mt-n1 ml-4 small-hint">Don’t know your URL? Read our guide <a>here</a>.</p>
              </v-col>
                <v-col cols="3">
                  <v-btn class="primary rounded verify-button" large block :class="{'disable-events': $v.storeUrl.$invalid, 'verified-button': isValidUrl}" @click="validateURL">
                    <span v-if="!isValidUrl">
                      Verify
                    </span>
                    <span v-else>
                      Verified
                    </span>
                  </v-btn>
                </v-col></v-row>
           </v-card>
          </v-col>
        </v-row>
        <v-row class="continue-button-wrapper">
          <v-col offset="1">
            <v-btn
                block
                class="primary continue-button"
                height="48px"
                :class="{'disable-events': !isValidUrl }"
                @click="registerStore"
            >Continue      <v-icon class="right-arrow">
              mdi-arrow-right
            </v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { required, url, sameAs } from "vuelidate/lib/validators";
import {REGISTER_SHOP} from "@/store/actions.type";
import { mapGetters } from "vuex";

export default {
  name: "VerifyStoreURL",
  data() {
    return {
      isValidUrl: false,
      inValidUrl: false,
      storeUrl: "https://",
      loading: false, // loading from store shop is not utilised, because we are not calling for any action here.
    };
  },
  validations: {
    storeUrl: { required, url },
    isValidUrl: { sameAs: sameAs( () => true )}
  },
  computed: {
    ...mapGetters('shop', ["newShopType", "currentShop"]),
    ...mapGetters('auth', ["currentUserID", "currentUser"]),
    checkURLErrors() {
      const urlErrors = [];
      if (!this.$v.storeUrl.$dirty) return urlErrors;
      !this.$v.storeUrl.url && urlErrors.push("Must be valid URL");
      !this.$v.storeUrl.required && urlErrors.push("URL is required");
      !this.$v.isValidUrl.sameAs && urlErrors.push("Store is not reachable")
      return urlErrors;
    },
  },
  methods: {
    reset(){
      this.isValidUrl = false;
      this.inValidUrl = false;
    },
    validateURL(){
      this.loading = true;
      let config = {
        method: "HEAD",
        mode: "no-cors"
      };
      const pingRequest = new Request(this.storeUrl, config);
      fetch(pingRequest)
          .then(() => {
            this.isValidUrl = true;
            this.inValidUrl = false;
          })
          .catch(e => {
            console.log(e);
            this.isValidUrl = false;
            this.inValidUrl = true;
          });
      this.loading = false;
    },
    registerStore(){
      this.$v.$touch();
      if (!this.$v.$invalid){
        //remove https from the Store Url
        let withoutHttps;
        withoutHttps = this.storeUrl.replace(/^http(s)*:\/\//, "");
        withoutHttps = withoutHttps.replace(/\/$/, "");
        this.$store.dispatch(`shop/${REGISTER_SHOP}`, {
          user_id: this.currentUserID,
          url: withoutHttps,
          platform_api_version: "v3",
          platform: "woocommerce",
          type: this.newShopType
        }).then((response) => {
          let redirectUrl = response.redirect_url;
          redirectUrl += process.env.VUE_APP_WOO_REDIRECT_URI;
          window.location.href = redirectUrl;
        })
      }
    }
  }
}
</script>

<style scoped>
.page-wrapper{
  margin-top: 3%;
}
.heading-text-wrapper{
  margin-top: 7%;
}
.card-wrapper{
  margin-top: 6%;
}
.filled{
  fill: #fcb058;
  stroke-width: 1;
  stroke: #f57c00;
}
.not-filled{
  fill: none;
  stroke-width: 1;
  stroke: #f57c00;
}
.line{
  stroke-width: 2;
  stroke:#f57c00
}
.read-guide{
  margin-top: 6%;
}
.small-hint{
  opacity: 0.6;
  line-height: 1.33;
}
.verify-url-card{
  height: 172px;
  width: 585px;
}
.verify-button{
  margin-top: 5%;
  font-size: 14px;
}
.continue-button-wrapper{
  margin-top: 6%;
}
.right-arrow{
  margin-left: 10px;
  margin-top: 4px;
  width: 24px;
  height: 24px;
}
.disable-events {
  opacity: 0.5;
  pointer-events: none
}
.container-max-width{
  max-width: 1200px;
  min-width: 768px !important;
}
.verified-button{
  background-color: green !important;
}
</style>